import React from "react"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import SiteBanner from "../components/site/siteBanner"
import SiteText from "../components/site/siteText"
import SiteImage from "../components/site/siteImage"
import SiteQuote from "../components/site/siteQuote"
import SiteSlideshow from "../components/site/siteSlideshow"
import SiteBrowserImage from "../components/site/siteBrowserImage"
import SiteMobileImages from "../components/site/siteMobileImages"
import SitePagination from "../components/site/sitePagination"

const siteTemplate = ({ data, ...props }) => {
    const { sanitySite } = data;
    const {
        title,
        long_title,
        roles,
        tag_line,
        website_url,
        description,
        shopify_plus,
        featured_images,
        banner_text_color,
        slug,
        main_image: {
            asset: {
                fluid: mainFluid
            }
        },
        main_image_mobile: {
            asset: {
                fluid: mobileFluid
            }
        },
        meta_image: {
            asset: {
                fixed: {
                    src: metaImage
                }
            }
        }
    } = sanitySite;
    const { pageContext } = props;
    const metaDescription = description;
    const metaUrl = 'https://pointercreative.com/site/'+slug;
    return (
        <>
            <SEO
                title={title}
                description={metaDescription}
                image={metaImage}
                url={metaUrl}
            />
            <SiteBanner
                fluid={mainFluid}
                mobileImage={mobileFluid}
                title={title}
                longTitle={long_title}
                shopifyPlus={shopify_plus}
                textColor={banner_text_color.hex}
            />
            <SiteText
                roles={roles}
                tagLine={tag_line}
                description={description}
            />
            {featured_images.map((content, i) => {
                const { __typename } = content;
                let result = null;
                switch (__typename) {
                    case 'SanityMobileImages':
                        result = <SiteMobileImages content={content} key={`section-${i}`}></SiteMobileImages>
                        break;
                    case 'SanityBrowserImage':
                        result = <SiteBrowserImage content={content} webSiteUrl={website_url} key={`section-${i}`}></SiteBrowserImage>
                        break;
                    case 'SanityQuotes':
                        result = <SiteQuote content={content} key={`section-${i}`}></SiteQuote>
                        break;
                    case 'SanitySlideshow':
                        result = <SiteSlideshow content={content} key={`section-${i}`}></SiteSlideshow>
                        break;
                    case 'SanitySiteImage':
                        result = <SiteImage content={content} key={`section-${i}`}></SiteImage>
                        break;
                    default:
                        break;
                }
                return result;
            })}
            <SitePagination props={pageContext} />
        </>
    )
};

export default siteTemplate

export const pageQuery = graphql`
    query siteQuery($slug: String!) {
        sanitySite(slug: { eq: $slug }) {
            id
            title
            long_title
            slug
            tag_line
            website_url
            description
            shopify_plus
            banner_text_color {
                hex
            }
            roles {
                role_name
            }
            featured_images {
                __typename
                ... on SanitySiteImage {
                    narrow,
                    image {
                        asset {
                            fluid(maxWidth: 1440) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                    mobile_image_enable
                    mobile_image {
                        asset {
                            fluid(maxWidth: 650) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                }
                ... on SanityQuotes {
                    quote_block
                    written_by
                    text_color {
                        hex
                    }
                    frame_color {
                        hex
                    }
                }
                ... on SanitySlideshow {
                    slides {
                        large_image {
                            asset {
                                fluid(maxWidth: 640) {
                                    ...GatsbySanityImageFluid_noBase64
                                }
                            }
                        }
                        small_image {
                            asset {
                                fluid(maxWidth: 448) {
                                    ...GatsbySanityImageFluid_noBase64
                                }
                            }
                        }
                    }
                }
                ... on SanityBrowserImage {
                    icon {
                        asset {
                            fluid(maxWidth: 48) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                    image {
                        asset {
                            fluid(maxWidth: 2272) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                    frame_color {
                        hex
                    }
                    text_color {
                        hex
                    }
                }
                ... on SanityMobileImages {
                    image_one {
                        asset {
                            fluid(maxWidth: 289) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                    image_two {
                        asset {
                            fluid(maxWidth: 289) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                    image_three {
                        asset {
                            fluid(maxWidth: 289) {
                                ...GatsbySanityImageFluid_noBase64
                            }
                        }
                    }
                }
            }
            main_image {
                asset {
                    fluid(maxWidth: 1440) {
                        ...GatsbySanityImageFluid_noBase64
                    }
                }
            }
            main_image_mobile {
                asset {
                    fluid(maxWidth: 650) {
                        ...GatsbySanityImageFluid_noBase64
                    }
                }
            }
            thumbnail_image {
                asset {
                    fluid(maxWidth: 1000) {
                        ...GatsbySanityImageFluid_noBase64
                    }
                }
            }
            meta_image {
                asset {
                    fixed(width: 1200, height: 630) {
                        ...GatsbySanityImageFixed_noBase64
                    }
                }
            }
        }
    }
`;
