import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const BrowserDiv = styled.div`
    position: relative;
    overflow: hidden;
    padding-bottom: 60px;

    @media only screen and (min-width: 750px) {
        padding-bottom: 128px;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 1180px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const BrowserFrameBackgroundDiv = styled.div`
    @media only screen and (min-width: 750px) {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${({ background }) => background};
        padding-right: 3000px;
        margin-right: -3000px;
    }

    @media only screen and (min-width: 1200px) {
        left: 20px;
    }
`;

const BrowserFrameDiv = styled.div`
    position: relative;
    z-index: 5;
    width: 100%;

    @media only screen and (min-width: 750px) {
        padding: 96px 0 66px 80px;
    }
`;

const BrowserWindowDiv = styled.div`
    position: relative;
    width: 100%;
    height: 812px;
    overflow: hidden;

    @media only screen and (min-width: 750px) {
        overflow-y: scroll;
        height: 648px;
    }
`;

const BrowserHeaderDiv = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    background: #DADADA;
    padding: 4px 12px;
`;

const CircleSpan = styled.span`
    position: relative;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    font-size: 16px;
    background: #FFFFFF;
`;

const StyledLink = styled.a`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    font-family: 'Graphik Regular', Helvetica, Arial, sans-serif;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    color: ${({ color }) => color};

    &:hover {
        opacity: 0.5;
    }
`;

const LinkDiv = styled.div`
    position: relative;
    margin: 15px 0 0 20px;

    @media only screen and (min-width: 750px) {
        margin-left: 0;
    }
`;

const StyledImage = styled.img`
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
`;

const stripUrl = ({ webSiteUrl }) => webSiteUrl
    .replace('https://', '')
    .replace('http://', '');

const SiteBrowserImage = ({ content: { image, frame_color, text_color, icon }, webSiteUrl }) => (
    <BrowserDiv>
        <WrapperDiv>
            <BrowserFrameDiv>
                <BrowserHeaderDiv><CircleSpan/><CircleSpan/><CircleSpan/></BrowserHeaderDiv>
                <BrowserWindowDiv>
                    <Img fluid={image.asset.fluid}/>
                </BrowserWindowDiv>
                <LinkDiv>
                    <StyledImage src={icon.asset.fluid.src}/>
                    <StyledLink href={webSiteUrl} target="_blank" color={text_color.hex}>visit {stripUrl({ webSiteUrl })}</StyledLink>
                </LinkDiv>
            </BrowserFrameDiv>
            <BrowserFrameBackgroundDiv background={frame_color.hex}></BrowserFrameBackgroundDiv>
        </WrapperDiv>
    </BrowserDiv>
);

export default SiteBrowserImage