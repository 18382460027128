import React from "react"
import styled from "styled-components"
/* eslint-disable jsx-a11y/accessible-emoji */

const QuoteDiv = styled.div`
    position: relative;
    padding: 52px 0 54px 0;
    background: ${({ background }) => background};

    @media only screen and (min-width: 750px) {
        padding: 95px 0;
    }
`;

const WrapperDiv = styled.div`
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1180px;
    position: relative;
`;

const QuoteText = styled.h3`
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
    color: ${({ textColor }) => textColor};
    margin-bottom: 25px;

    @media only screen and (min-width: 750px) {
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -2px;
    }

    @media only screen and (min-width: 1024px) {
        font-size: 72px;
        line-height: 80px;
    }
`;

const WrittenByP = styled.p`
    font-size: 16px;
    line-height: 21px;
    color: ${({ textColor }) => textColor};
    margin-bottom: 0;
`;

const EmojiSpeech = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 16px;
`;

const SiteQuote = ({ content: { frame_color, quote_block, written_by, text_color } }) => (
    <QuoteDiv background={frame_color.hex}>
        <WrapperDiv>
            <QuoteText textColor={text_color.hex}>{quote_block}</QuoteText>
            <WrittenByP textColor={text_color.hex}>
                <EmojiSpeech role="img" aria-label="Speech Bubble Emoji">💬</EmojiSpeech>
                <span>{written_by}</span>
            </WrittenByP>
        </WrapperDiv>
    </QuoteDiv>
);

export default SiteQuote