import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BannerDiv = styled.div`
    position: relative;
    height: 100vh;
`;

const ImageDivDesktop = styled(BackgroundImage)`
    position: absolute !important;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    display: none;

    @media only screen and (min-width: 650px) {
        display: block;
    }
`;

const ImageDivMobile= styled(BackgroundImage)`
    position: absolute !important;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    display: block;
    background-position: top center;

    @media only screen and (min-width: 650px) {
        display: none;
    }

    &:before {
        background-position: top center;
    }
`;

const WrapperDiv = styled.div`
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1180px;
    position: relative;
`;

const BannerText = styled.div`
    position: absolute;
    bottom: 39px;
    left: 0;
    width: 100%;

    @media only screen and (min-width: 750px) {
        bottom: 59px;
    }
`;

const BannerTitle = styled.h1`
    font-size: 60px;
    line-height: 52px;
    letter-spacing: -3px;
    margin: 0 0 5px -5px;
    color: ${({ textColor }) => textColor};

    @media only screen and (min-width: 750px) {
        font-size: 80px;
        line-height: 72px;
    }
`;

const BannerPlusTitle = styled.span`
    font-size: 14px;
    line-height: 16px;
    color: ${({ textColor }) => textColor};
`;

const SiteBanner = ({ fluid, title, longTitle, shopifyPlus, textColor, mobileImage }) => (
    <BannerDiv>
        <ImageDivDesktop Tag="div" fluid={fluid}></ImageDivDesktop>
        <ImageDivMobile Tag="div" fluid={mobileImage}></ImageDivMobile>
        <BannerText>
            <WrapperDiv>
                <BannerTitle textColor={textColor}>
                    {longTitle ? longTitle : title}
                </BannerTitle>
                {shopifyPlus ?
                    <BannerPlusTitle textColor={textColor}>Powered by Shopify Plus</BannerPlusTitle>
                : null}
            </WrapperDiv>
        </BannerText>
    </BannerDiv>
)

export default SiteBanner