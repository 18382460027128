import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
/* eslint-disable jsx-a11y/accessible-emoji */

const PaginationDiv = styled.div`
    position: relative;
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px 53px 20px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px 83px 20px;
    }
`;

const NavigationTextP = styled.p`
    margin: 0 0 10px 0;

    @media only screen and (min-width: 750px) {
        margin: 0 0 23px 0;
    }
`;

const EmojiDown = styled.span`
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
`;

const NextProject = styled.span`
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
    color: #282828;
`;

const MenuTitle = styled.span`
    font-family: 'Graphik Bold', Helvetica, Arial, sans-serif;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -2px;
    color: #DADADA;

    @media only screen and (min-width: 750px) {
        font-size: 80px;
        line-height: 80px;
        letter-spacing: -4px;
    }
`

const StyledLink = styled(Link)`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    text-decoration: none;
    display: inline-block;
    float: right;
    margin-top: -36px;

    @media only screen and (min-width: 750px) {
        margin-top: -59px;
    }

    &:hover {
        opacity: 0.5;
    }

    svg {
        width: 70px;
        height: 70px;

        @media only screen and (min-width: 750px) {
            width: 134px;
            height: 134px;
        }
    }
`;

const BannerDiv = styled.div`
    width: 100%;
    height: 650px;
    position: relative;

    @media only screen and (min-width: 750px) {
        height: 640px;
    }
`;

const ImageDiv = styled.div`
    position: absolute!important;
    background-image: url(${({ url }) => url});
    background-color: #040e18;
    background-size: cover;
    background-repeat:no-repeat;
    background-position: top center;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
`;

const SitePagination = ({ props }) => {
    const { next, nextImage, nextTitle } = props;
    const nextImageUrl = nextImage.replace('w=1000&h=667','w=1440&h=900');

    return (
        <PaginationDiv>
            <WrapperDiv>
                <NavigationTextP>
                    <EmojiDown role="img" aria-label="Pointing Down Emoji">👇</EmojiDown>
                    <NextProject>Next Project</NextProject>
                </NavigationTextP>
                <MenuTitle>{nextTitle}</MenuTitle>
                <StyledLink to={`/site/${next}`}>
                    <svg width="134" height="134" viewBox="0 0 134 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31 67H97" stroke="#111111" strokeWidth="2" strokeMiterlimit="10" vectorEffect="non-scaling-stroke"/>
                        <path d="M73 43L97 67L73 91" stroke="#111111" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" vectorEffect="non-scaling-stroke"/>
                        <path d="M67 133C103.451 133 133 103.451 133 67C133 30.5492 103.451 1 67 1C30.5492 1 1 30.5492 1 67C1 103.451 30.5492 133 67 133Z" stroke="#E8E8E8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" vectorEffect="non-scaling-stroke"/>
                    </svg>
                </StyledLink>
            </WrapperDiv>
            <BannerDiv>
                <a href={`/site/${next}`}>
                    <ImageDiv url={nextImageUrl}></ImageDiv>
                </a>
            </BannerDiv>
        </PaginationDiv>
    )
}

export default SitePagination