import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const MobileImagesDiv = styled.div`
    position: relative;
    overflow: hidden;
    padding: 60px 0 25px 0;

    @media only screen and (min-width: 750px) {
        padding: 128px 0 123px 0;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
`;

const MobileImageDiv = styled.div`
    text-align: center;

    @media only screen and (min-width: 750px) {
        position: relative;
        float: left;
        width: 33.33%;
        padding: 15px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 0;
    }

    &:nth-child(1) {
        @media only screen and (min-width: 750px) {
            text-align: left;
        }
    }

    &:nth-child(3) {
        @media only screen and (min-width: 750px) {
            text-align: right;
        }
    }
`;

const MobileImage = styled(Img)`
    width: 100%;
    max-width: 325px;
    display: inline-block;
    border: 18px solid #EDEDED;
    border-radius: 36px;
    padding: 9px 9px 42px 9px;
    margin: 0 0 30px 0;
`;

const SiteMobileImages = ({ content: { image_one, image_two, image_three } }) => (
    <MobileImagesDiv>
        <WrapperDiv>
            <MobileImageDiv>
                <MobileImage fluid={image_one.asset.fluid}/>
            </MobileImageDiv>
            <MobileImageDiv>
                <MobileImage fluid={image_two.asset.fluid}/>
            </MobileImageDiv>
            <MobileImageDiv>
                <MobileImage fluid={image_three.asset.fluid}/>
            </MobileImageDiv>
        </WrapperDiv>
    </MobileImagesDiv>
);

export default SiteMobileImages