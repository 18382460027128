import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const ImageDiv = styled.div`
    position: relative;
`;

const WrapperDiv = styled.div`
    margin: 0 auto;
    position: relative;
    max-width: ${({ maxWidth }) => maxWidth};
`;

const ImageMobile = styled.div`
    padding-bottom: 60px;

    @media only screen and (min-width: 650px) {
        display: none;
    }
`;

const ImageDesktop = styled.div`
    display: none;
    padding-bottom: 128px;

    @media only screen and (min-width: 650px) {
        display: block;
    }
`;

const SiteImage = ({ content: { narrow, image, mobile_image, mobile_image_enable } }) => (
    <ImageDiv>
        <WrapperDiv maxWidth={narrow ? '1140px': 'none'}>
            {mobile_image_enable ?
                <ImageMobile>
                    <Img fluid={mobile_image.asset.fluid}/>
                </ImageMobile>
            : null}
            <ImageDesktop>
                <Img fluid={image.asset.fluid}/>
            </ImageDesktop>
        </WrapperDiv>
    </ImageDiv>
);

export default SiteImage