import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const SlideshowDiv = styled.div`
    position: relative;
    display: block;
    overflow: hidden;

    @media only screen and (min-width: 750px) {
        max-height: 896px;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const ImageOneDiv = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-bottom: 60px;

    @media only screen and (min-width: 750px) {
        max-width: 640px;
        width: 60%;
        float: left;
        padding-right: 20px;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 1200px) {
        padding-right: 0;
    }
`;

const ImageTwoDiv = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 71%;

    @media only screen and (min-width: 750px) {
        max-width: 448px;
        width: 40%;
        float: right;
        padding-left: 20px;
    }

    @media only screen and (min-width: 1200px) {
        padding-left: 0;
    }
`;

const Slide = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 60px;

    @media only screen and (min-width: 750px) {
        padding-bottom: 128px;
    }
`;

const Button = styled.button`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    float: right;
    margin-top: 20px;
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    bottom: -8px;
    right: -78px;

    @media only screen and (min-width: 750px) {
        margin-top: 40px;
        position: relative;
        right: auto;
        bottom: auto;
    }

    &:hover {
        opacity: 0.5;
    }

    svg {
        width: 48px;
        height: 48px;
    }
`;

const SiteSlideshow = ({ content: { slides } }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const [currentLimit] = useState(slides.length);

    const nextSlide = () => {
        let index = currentSlide + 1;
        if(index >= currentLimit) { index = 0; }
        return setCurrentSlide(index);
    };

    const slideShow = slides.map(({ large_image, small_image }, i) => (
        <Slide key={`slide-${i+1}`}>
            <WrapperDiv>
                <ImageOneDiv>
                    <Img fluid={large_image.asset.fluid}/>
                </ImageOneDiv>
                <ImageTwoDiv>
                    <Img fluid={small_image.asset.fluid}/>
                    <Button onClick={nextSlide}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 24H34" stroke="#111111" strokeWidth="2" strokeMiterlimit="10" vectorEffect="non-scaling-stroke"/>
                            <path d="M26 16L34 24L26 32" stroke="#111111" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" vectorEffect="non-scaling-stroke"/>
                            <path d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" stroke="#E8E8E8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" vectorEffect="non-scaling-stroke"/>
                        </svg>
                    </Button>
                </ImageTwoDiv>
            </WrapperDiv>
        </Slide>
    ));

    return (
        <SlideshowDiv>
            {slideShow[currentSlide]}
        </SlideshowDiv>
    )
};

export default SiteSlideshow