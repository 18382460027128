import React from "react"
import styled from "styled-components"

const BannerDiv = styled.div`
    position: relative;
    overflow: hidden;
    padding: 61px 0 52px 0;

    @media only screen and (min-width: 750px) {
        padding: 80px 0 68px 0;
    }

    @media only screen and (min-width: 1024px) {
        padding: 100px 0 88px 0;
    }
`;

const WrapperDiv = styled.div`
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1180px;
    position: relative;
`;

const TagLineDiv = styled.div`
    position: relative;

    @media only screen and (min-width: 1024px) {
        width: 33.33%;
        float: left;
    }
`;

const TagLineH3 = styled.h3`
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1.5px;
    color: #DADADA;
    margin: -8px 0 40px 0;

    @media only screen and (min-width: 750px) {
        font-size: 48px;
        line-height: 52px;
        letter-spacing: -2px;
    }

    @media only screen and (min-width: 1024px) {
        max-width: 275px;
        margin: -8px 0 0 0;
        letter-spacing: -2px;
        white-space: pre-wrap;
    }
`;

const DescriptionDiv = styled.div`
    position: relative;

    @media only screen and (min-width: 750px) {
        width: 74%;
        float: left;
    }

    @media only screen and (min-width: 1024px) {
        width: 48%;
    }
`;

const SectionHeaderH4 = styled.h4`
    font-size: 14px;
    line-height: 16px;
    color: #282828;
    margin: 0 0 8px 0;
`;

const DescriptionP = styled.p`
    font-size: 16px;
    line-height: 28px;
    color: #282828;
    margin-bottom: 26px;
    max-width: 470px;

    @media only screen and (min-width: 750px) {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 1024px) {
        max-width: 425px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 470px;
    }
`;

const RolesDiv = styled.div`
    position: relative;

    @media only screen and (min-width: 750px) {
        width: 25%;
        float: left;
    }

    @media only screen and (min-width: 1024px) {
        width: 15%;
    }
`;

const RoleP = styled.p`
    font-family: 'Graphik Regular', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: #282828;
    margin: 0;
    width: 50%;
    display: inline-block;

    @media only screen and (min-width: 750px) {
        width: 100%;
        display: block;
    }
`;

const SiteText = ({ tagLine, description, roles }) => (
    <BannerDiv>
        <WrapperDiv>
            <TagLineDiv>
                <TagLineH3>{tagLine}</TagLineH3>
            </TagLineDiv>
            <DescriptionDiv>
                <SectionHeaderH4>The context</SectionHeaderH4>
                <DescriptionP>{description}</DescriptionP>
            </DescriptionDiv>
            <RolesDiv>
                <SectionHeaderH4>Our roles</SectionHeaderH4>
                {roles.map(({ role_name }) => (
                    <RoleP key={role_name}>{role_name}</RoleP>
                ))}
            </RolesDiv>
        </WrapperDiv>
    </BannerDiv>
)

export default SiteText